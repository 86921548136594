.container-login {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.logo-login {
    display: block;
    margin: -20px auto;
    width: 280px;
}

#login {
    width: 250px;
    height: auto;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.212);
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.137);
    padding: 20px;
    border-radius: 20px;
    transition: 0.8s;
}

#login input, #login  button {
    width: 100%;
    height: 40px;
    border-radius: 50px;
    border: none;
    padding-left: 5px;
    font-size: 1.1em;
    margin-bottom: 20px;
    font-weight: bold;
}

#login button {
    background-color: rgb(235, 150, 72);
    color: #F1F1F1;
}

.no-account {
    text-align: right;
    font-size: 1em;
    font-style: italic;
}

.no-account span {
    font-weight: bold;
    font-size: 1.1em;
    color: orange;
}
