.container-home{
    position: relative;
    width: 100vw;
    height: 100vh;
}

.logo-app-home {
    display: block;
    margin: -50px auto;
    width: 100vw;
    max-width: 500px;
}

#home img {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    animation:shadowChange 10s infinite ease-in-out;
    border-radius: 100%;
    background-color: rgba(36, 181, 238, 0.185);
    background-position: center;
    filter: blur(1.1px);
    -webkit-animation:shadowChange 10s infinite ease-in-out;
}

#home .btn-go-party {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    border-radius: 20px;
    border: none;
    background-color: rgb(250, 123, 4);
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #F1F1F1;
}




@keyframes shadowChange {
    0% {
        box-shadow: 0px 0Px 100Px 1px #6ece6e7e;
        transform: translate(-50%, -50%) rotate(0deg);
    }
    20% {
        box-shadow: 0px 2Px 120Px 1px #db141479;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    50% {
        box-shadow: -2px 2Px 80Px 1px #3a419277;
        transform: translate(-50%, -50%) rotate(90deg);
    }
    70% {
        box-shadow: -3px 3Px 100Px 1px #dd822c93;
        transform: translate(-50%, -50%) rotate(180deg);
    }
    90% {
        box-shadow: 0px 0Px 100Px 1px #f1f1f156;
        transform: translate(-50%, -50%) rotate(240deg);
    }
    100% {
        box-shadow: 0px 0Px 100Px 1px #6ece6e7e;
        transform: translate(-50%, -50%) rotate(360deg);
    }

}


.box-eventInCourt {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
    background-color: #f1f1f125;
    padding: 20px;
    border-radius: 20px ;
    -webkit-border-radius: 20px ;
    -moz-border-radius: 20px ;
    -ms-border-radius: 20px ;
    -o-border-radius: 20px ;
}

.btn-box-enventInCourt {
    margin-top: 20px;
    padding: 20px;
    color: #ffff;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: rgb(255, 38, 0);

}
.btn-box-enventInCourt:nth-child(1) {
    background-color: rgb(255, 166, 0);

}