.newEventPageTitle {
    margin: 20px;
}
.box-form-newEvent {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
}

.form-input-newEvent {
    display: block;
    margin: 0 auto;
    height: 30px;
    width: 200px;
    border : none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px 10px;
    font-size: 1.2em;

}
.btn-spotifyConnect {
    background-color: green;
    color: #F1F1F1;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    border : none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px 10px;
    font-size: 1.2em;
}