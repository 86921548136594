.Spotify-info-connect {
    position: absolute;
    right: 10px;
    top: 5px;
    color: green;
    text-align: center;
    font-size: 14px;
}


.container-event {
    position: absolute;
    margin-top: 50px;
    width: 100vw;
    overflow: auto;
}
.name-of-party {
    text-align: center;
    font-size: 1.2em;
    color: #f1c40f;
    text-transform:UPPERCASE;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.267);
    letter-spacing: 2px;
}

.player-muznext-app{
    max-width: 100vw;
}

.box_btn_select-view {
    width: 100vw;
    display: flex;
    flex-direction: row;
    background-color: #3c3a31c0;
}
.btn_select-view  {
    display: block;
    width: 150px;
    margin: 0px auto;
    padding: 5px 10px;
    font-size: 15px;
    color: #f1f1f1;
    font-weight: bold;
    text-shadow: 0px 1px 5px #000;
    background-color: #19C4EB;
    border: none;
    border-left: 1px solid #000;
    box-shadow: 0px 0px 5px 1px #f1f1f146;
}

.btn_select-view:focus {
    background-color: rgb(255, 102, 0);
}


.playlistItem {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin:10px 10px 15px;
    padding: 10px;
    border-radius: 10px;
    color: #F1F1F1;
    box-shadow: 0px 0px 5px rgba(255,255,255,0.5);
  
}
  
.playlistItem img {
    margin-right: 20px;
}
.playlistItem span {
    margin-right: 50px ;
}
.playlistItem .artisteName {
    margin: 0px;
}
.playlistItem i {
    position: absolute;
    font-size: 2em;
    margin: 15px;
    right: 0px;
    cursor: pointer;
}

.inputSearchTrack {
    display: block;
    margin: 10px auto;
    width: 300px;
    padding: 10px 20px;
    border-radius: 20px;
}

.searchNextItem {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin:10px;
    padding: 10px;
    border-radius: 10px;
    color: #F1F1F1;
    box-shadow: 0px 0px 5px rgba(255,255,255,0.5);
  
  }
  
  .searchNextItem img {
    margin-right: 20px;
  }
  
  .searchNextItem span {
    flex-grow: 4; 
    text-align: left;
  }
  
  .searchNextItem i {
    margin-top:15px;
    font-size: 3em;
    cursor: pointer;
    color: rgb(227, 235, 224);
  }

  .qrcode-access {
        display: block;
        margin: 10px auto;
        padding: 10px;
        background-color: #F1F1F1;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        animation: shadowChange 5s infinite ease-in-out;
        -webkit-animation: shadowChange 2s infinite ease-in-out;
}

@keyframes shadowChange {
    0% {box-shadow: 0px 0px 65Px 20px #6ece6e73;}
    20% {box-shadow:  0px 0px 65Px 20px #db14147c;}
    50% {box-shadow:  0px 0px 65Px 20px rgba(0, 255, 242, 0.349);}
    70% {box-shadow: 0px 0px 65Px 20px #dd812c56;}
    100% {box-shadow: 0px 0px 65Px 20px #f1f1f146;}
}