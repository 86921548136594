.info_connect {
    position: absolute;
    right: 5px;
}

.name_of_event {
    text-align: center;
    font-size: 1.2em;
    color: #f1c40f;
    text-transform:UPPERCASE;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.267);
    letter-spacing: 2px;
}

.playbox-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 350px;
    margin: 10px auto;
}

.title-no-connect {
    text-align: center;
    margin-bottom: 20px;
}

.login-follow-event {
    margin-top: -170px;
}

.nobodyBidding{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(0, 162, 255, 0.863);
}

.inputSearchTrack {
    border: none;
    font-size: 18px;
}

.boxBiddingWarInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 10px auto;
}

.name_user {
    text-transform: uppercase;
    color: coral;
}
.Djcoin_info {
    color: coral;
}
.boxBiddingWarInfo-info {
    margin-left: 20px;
}
.input-biddingWar{
    width: 30px;
    height: 20px;
    padding: 15px;
    margin: 5px;
    border-radius: 10px ;
    -webkit-border-radius: 10px ;
    -moz-border-radius: 10px ;
    -ms-border-radius: 10px ;
    -o-border-radius: 10px ;
    border: none;
}
.btn-bidding {
    width: 70px;
    height: 50px;
    margin: 5px;
    padding: 5px;
    border-radius: 10px ;
    -webkit-border-radius: 10px ;
    -moz-border-radius: 10px ;
    -ms-border-radius: 10px ;
    -o-border-radius: 10px ;
    border: none;
    background-color: greenyellow;
}