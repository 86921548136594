.userInfoPageTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.userInfoPageBtnDeconnect {
    display: block;
    margin: 10px auto;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: #FFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: rgb(196, 66, 66);
    font-weight: bold;
}

#userInfoPage ul {
 margin: 10px;
}
#userInfoPage ul li {
    margin: 10px;
    padding: 10px;
    background-color: rgb(44, 42, 42);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px 0px 2px 1px #ffffffb9;
}