@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Reset */
* {
  margin: 0;
  padding: 0;
}
ul {list-style: none;}

/* End Reset */


body {
  position: relative;
  background-color: #000;
  color: #F1F1F1;
  font-family: 'Roboto', sans-serif;
}
.link-router {
  text-decoration: none;
  color: #F1F1F1;
}

#principal-Nav {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: rgb(41, 185, 252);
    overflow: hidden;
    z-index: 9999;
}

.principal-Nav-ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: wrap;
            flex-direction: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.principal-Nav-ul-li {
    margin: 5px;
    padding: 10px 40px ;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 1.6em;
    color: rgb(234, 234, 241);
    cursor: pointer;
    box-shadow: 0px 0px 15px 5px #f1f1f18c;
    background: rgba(255, 255, 255, 0.041);
}

.principal-Nav-ul-li:active {
    background-color: orange;
}

.principal-Nav-ul-li:active > i {
    color: rgb(241, 184, 24);
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.principal-Nav-ul-li i {
    display: block;
    text-shadow: 0px 0px 10px #000000;
    text-align: center;
}

.container-home{
    position: relative;
    width: 100vw;
    height: 100vh;
}

.logo-app-home {
    display: block;
    margin: -50px auto;
    width: 100vw;
    max-width: 500px;
}

#home img {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 200px;
    animation:shadowChange 10s infinite ease-in-out;
    border-radius: 100%;
    background-color: rgba(36, 181, 238, 0.185);
    background-position: center;
    -webkit-filter: blur(1.1px);
            filter: blur(1.1px);
    -webkit-animation:shadowChange 10s infinite ease-in-out;
}

#home .btn-go-party {
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 15px;
    border-radius: 20px;
    border: none;
    background-color: rgb(250, 123, 4);
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #F1F1F1;
}




@-webkit-keyframes shadowChange {
    0% {
        box-shadow: 0px 0Px 100Px 1px #6ece6e7e;
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
                transform: translate(-50%, -50%) rotate(0deg);
    }
    20% {
        box-shadow: 0px 2Px 120Px 1px #db141479;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
    }
    50% {
        box-shadow: -2px 2Px 80Px 1px #3a419277;
        -webkit-transform: translate(-50%, -50%) rotate(90deg);
                transform: translate(-50%, -50%) rotate(90deg);
    }
    70% {
        box-shadow: -3px 3Px 100Px 1px #dd822c93;
        -webkit-transform: translate(-50%, -50%) rotate(180deg);
                transform: translate(-50%, -50%) rotate(180deg);
    }
    90% {
        box-shadow: 0px 0Px 100Px 1px #f1f1f156;
        -webkit-transform: translate(-50%, -50%) rotate(240deg);
                transform: translate(-50%, -50%) rotate(240deg);
    }
    100% {
        box-shadow: 0px 0Px 100Px 1px #6ece6e7e;
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }

}




@keyframes shadowChange {
    0% {
        box-shadow: 0px 0Px 100Px 1px #6ece6e7e;
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
                transform: translate(-50%, -50%) rotate(0deg);
    }
    20% {
        box-shadow: 0px 2Px 120Px 1px #db141479;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
    }
    50% {
        box-shadow: -2px 2Px 80Px 1px #3a419277;
        -webkit-transform: translate(-50%, -50%) rotate(90deg);
                transform: translate(-50%, -50%) rotate(90deg);
    }
    70% {
        box-shadow: -3px 3Px 100Px 1px #dd822c93;
        -webkit-transform: translate(-50%, -50%) rotate(180deg);
                transform: translate(-50%, -50%) rotate(180deg);
    }
    90% {
        box-shadow: 0px 0Px 100Px 1px #f1f1f156;
        -webkit-transform: translate(-50%, -50%) rotate(240deg);
                transform: translate(-50%, -50%) rotate(240deg);
    }
    100% {
        box-shadow: 0px 0Px 100Px 1px #6ece6e7e;
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }

}


.box-eventInCourt {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
    background-color: #f1f1f125;
    padding: 20px;
    border-radius: 20px ;
    -webkit-border-radius: 20px ;
    -moz-border-radius: 20px ;
    -ms-border-radius: 20px ;
    -o-border-radius: 20px ;
}

.btn-box-enventInCourt {
    margin-top: 20px;
    padding: 20px;
    color: #ffff;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    background-color: rgb(255, 38, 0);

}
.btn-box-enventInCourt:nth-child(1) {
    background-color: rgb(255, 166, 0);

}
.container-login {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.logo-login {
    display: block;
    margin: -20px auto;
    width: 280px;
}

#login {
    width: 250px;
    height: auto;
    position: absolute;
    top: 53%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.212);
    box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.137);
    padding: 20px;
    border-radius: 20px;
    transition: 0.8s;
}

#login input, #login  button {
    width: 100%;
    height: 40px;
    border-radius: 50px;
    border: none;
    padding-left: 5px;
    font-size: 1.1em;
    margin-bottom: 20px;
    font-weight: bold;
}

#login button {
    background-color: rgb(235, 150, 72);
    color: #F1F1F1;
}

.no-account {
    text-align: right;
    font-size: 1em;
    font-style: italic;
}

.no-account span {
    font-weight: bold;
    font-size: 1.1em;
    color: orange;
}

.userInfoPageTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.userInfoPageBtnDeconnect {
    display: block;
    margin: 10px auto;
    padding: 10px;
    border: none;
    border-radius: 10px;
    color: #FFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: rgb(196, 66, 66);
    font-weight: bold;
}

#userInfoPage ul {
 margin: 10px;
}
#userInfoPage ul li {
    margin: 10px;
    padding: 10px;
    background-color: rgb(44, 42, 42);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0px 0px 2px 1px #ffffffb9;
}
.container-search-spot {
    position: relative;
    width: 100vw;
    height: 100vh;
}
.title-input-search-spot {
    text-align: center;
    margin: 10px;
}
.newEventPageTitle {
    margin: 20px;
}
.box-form-newEvent {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -o-transform: translate(-50%);
}

.form-input-newEvent {
    display: block;
    margin: 0 auto;
    height: 30px;
    width: 200px;
    border : none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px 10px;
    font-size: 1.2em;

}
.btn-spotifyConnect {
    background-color: green;
    color: #F1F1F1;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    border : none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px 10px;
    font-size: 1.2em;
}
.Spotify-info-connect {
    position: absolute;
    right: 10px;
    top: 5px;
    color: green;
    text-align: center;
    font-size: 14px;
}


.container-event {
    position: absolute;
    margin-top: 50px;
    width: 100vw;
    overflow: auto;
}
.name-of-party {
    text-align: center;
    font-size: 1.2em;
    color: #f1c40f;
    text-transform:UPPERCASE;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.267);
    letter-spacing: 2px;
}

.player-muznext-app{
    max-width: 100vw;
}

.box_btn_select-view {
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color: #3c3a31c0;
}
.btn_select-view  {
    display: block;
    width: 150px;
    margin: 0px auto;
    padding: 5px 10px;
    font-size: 15px;
    color: #f1f1f1;
    font-weight: bold;
    text-shadow: 0px 1px 5px #000;
    background-color: #19C4EB;
    border: none;
    border-left: 1px solid #000;
    box-shadow: 0px 0px 5px 1px #f1f1f146;
}

.btn_select-view:focus {
    background-color: rgb(255, 102, 0);
}


.playlistItem {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    list-style: none;
    margin:10px 10px 15px;
    padding: 10px;
    border-radius: 10px;
    color: #F1F1F1;
    box-shadow: 0px 0px 5px rgba(255,255,255,0.5);
  
}
  
.playlistItem img {
    margin-right: 20px;
}
.playlistItem span {
    margin-right: 50px ;
}
.playlistItem .artisteName {
    margin: 0px;
}
.playlistItem i {
    position: absolute;
    font-size: 2em;
    margin: 15px;
    right: 0px;
    cursor: pointer;
}

.inputSearchTrack {
    display: block;
    margin: 10px auto;
    width: 300px;
    padding: 10px 20px;
    border-radius: 20px;
}

.searchNextItem {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    list-style: none;
    margin:10px;
    padding: 10px;
    border-radius: 10px;
    color: #F1F1F1;
    box-shadow: 0px 0px 5px rgba(255,255,255,0.5);
  
  }
  
  .searchNextItem img {
    margin-right: 20px;
  }
  
  .searchNextItem span {
    -webkit-flex-grow: 4;
            flex-grow: 4; 
    text-align: left;
  }
  
  .searchNextItem i {
    margin-top:15px;
    font-size: 3em;
    cursor: pointer;
    color: rgb(227, 235, 224);
  }

  .qrcode-access {
        display: block;
        margin: 10px auto;
        padding: 10px;
        background-color: #F1F1F1;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        animation: shadowChange 5s infinite ease-in-out;
        -webkit-animation: shadowChange 2s infinite ease-in-out;
}

@-webkit-keyframes shadowChange {
    0% {box-shadow: 0px 0px 65Px 20px #6ece6e73;}
    20% {box-shadow:  0px 0px 65Px 20px #db14147c;}
    50% {box-shadow:  0px 0px 65Px 20px rgba(0, 255, 242, 0.349);}
    70% {box-shadow: 0px 0px 65Px 20px #dd812c56;}
    100% {box-shadow: 0px 0px 65Px 20px #f1f1f146;}
}

@keyframes shadowChange {
    0% {box-shadow: 0px 0px 65Px 20px #6ece6e73;}
    20% {box-shadow:  0px 0px 65Px 20px #db14147c;}
    50% {box-shadow:  0px 0px 65Px 20px rgba(0, 255, 242, 0.349);}
    70% {box-shadow: 0px 0px 65Px 20px #dd812c56;}
    100% {box-shadow: 0px 0px 65Px 20px #f1f1f146;}
}
.info_connect {
    position: absolute;
    right: 5px;
}

.name_of_event {
    text-align: center;
    font-size: 1.2em;
    color: #f1c40f;
    text-transform:UPPERCASE;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.267);
    letter-spacing: 2px;
}

.playbox-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    width: 350px;
    margin: 10px auto;
}

.title-no-connect {
    text-align: center;
    margin-bottom: 20px;
}

.login-follow-event {
    margin-top: -170px;
}

.nobodyBidding{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(0, 162, 255, 0.863);
}

.inputSearchTrack {
    border: none;
    font-size: 18px;
}

.boxBiddingWarInfo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 10px auto;
}

.name_user {
    text-transform: uppercase;
    color: coral;
}
.Djcoin_info {
    color: coral;
}
.boxBiddingWarInfo-info {
    margin-left: 20px;
}
.input-biddingWar{
    width: 30px;
    height: 20px;
    padding: 15px;
    margin: 5px;
    border-radius: 10px ;
    -webkit-border-radius: 10px ;
    -moz-border-radius: 10px ;
    -ms-border-radius: 10px ;
    -o-border-radius: 10px ;
    border: none;
}
.btn-bidding {
    width: 70px;
    height: 50px;
    margin: 5px;
    padding: 5px;
    border-radius: 10px ;
    -webkit-border-radius: 10px ;
    -moz-border-radius: 10px ;
    -ms-border-radius: 10px ;
    -o-border-radius: 10px ;
    border: none;
    background-color: greenyellow;
}
