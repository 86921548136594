#principal-Nav {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: rgb(41, 185, 252);
    overflow: hidden;
    z-index: 9999;
}

.principal-Nav-ul {
    display: flex;
    flex-direction: wrap;
    justify-content: space-around;
}

.principal-Nav-ul-li {
    margin: 5px;
    padding: 10px 40px ;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 1.6em;
    color: rgb(234, 234, 241);
    cursor: pointer;
    box-shadow: 0px 0px 15px 5px #f1f1f18c;
    background: rgba(255, 255, 255, 0.041);
}

.principal-Nav-ul-li:active {
    background-color: orange;
}

.principal-Nav-ul-li:active > i {
    color: rgb(241, 184, 24);
    transform: scale(1.5);
}

.principal-Nav-ul-li i {
    display: block;
    text-shadow: 0px 0px 10px #000000;
    text-align: center;
}
