/* Reset */
* {
  margin: 0;
  padding: 0;
}
ul {list-style: none;}

/* End Reset */

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


body {
  position: relative;
  background-color: #000;
  color: #F1F1F1;
  font-family: 'Roboto', sans-serif;
}
.link-router {
  text-decoration: none;
  color: #F1F1F1;
}
